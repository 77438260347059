import React, { Suspense, useContext, useEffect, useState, useRef } from 'react'
import {
  Route,
  Switch,
  useHistory,
  useLocation
} from 'react-router-dom'
import { CContainer, CImg, CFade } from '@coreui/react'
import ImagePostIt from '../assets/images/post-it-jaune.svg'
// import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import './content.scss'

// routes config
import routes, { boxCessionSocieteLinks, boxDonationsSuccessionLinks, bilanPatrimonialLinks, boxFinanciersLinks, everywhereLinks, boxImmobilierLinks } from '../routes'
import not_found from '../assets/images/404.gif'
import { addToast, deleteToast } from '../store/actions/utils/toast'
import { UtilsContext } from '../store/context/utils'
import { logout } from '../store/actions/utils/login'
import { SimulateurContext } from '../store/context/simulateur'
import PostItJaune from '../assets/images/post-it-jaune.svg'
import { enableOrDisableCalculatriceKeyBoard, updatePostItData } from '../store/actions/simulateur'
import { divStyle } from './TheLayout'
import Calculator from '../components/Calculatrice/Calculatrice'
import ExitConfirmation from '../components/ExitConfirmation/ExitConfirmation';
import Postit from '../components/Postit/Postit'

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

const My404 = () => {
  return (
    <div className="d-flex flex-column align-items-center justify-content-center">
      <CImg
        className="shadow-sm"
        src={not_found}
        style={{ width: 300, height: 300, borderRadius: 150 }}
      />
      <h1 style={{ color: 'rgba(0,0,0,0.3)' }}>Page non trouvée !</h1>
    </div>
  )
}

const TheContent = ({ socket, cursor, setPostItToDeleteId, setCoordinates }) => {
  const history = useHistory();
  const [routes2, setRoutes2] = useState([]);
  const [items, setItems] = useState([])
  const [trouve, setTrouve] = useState(false);
  const [launch, setLaunch] = useState(false);
  const { utilsState, utilsDispatch } = useContext(UtilsContext);
  const { simulateurState, simulateurDispatch } = useContext(SimulateurContext);
  const [hasInvestissementsFinanciersBox, setHasInvestissementsFinanciersBox] = useState(JSON.parse(localStorage.getItem('user'))?.menus.find((element) => parseInt(element.ordre) === 3));
  const [hasCessionSocieteBox, setHasCessionSocieteBox] = useState(JSON.parse(localStorage.getItem('user'))?.menus.find((element) => parseInt(element.ordre) === 5));

  const elementDeplacableRef = useRef(null);
  const postitDeplacableRef = useRef(null);

  const handleMouseMove = (event) => {
    if (cursor?.current?.style) {
      cursor.current.style.top = `${event.clientY}px`;
      cursor.current.style.left = `${event.clientX}px`;
    }
  };

  useEffect(() => {
    // Liens communs peu importe les BOXES du client en question
    setRoutes2((prevState) => {
      return [
        ...prevState,
        ...everywhereLinks
      ]
    });

    // S'il y a la BOX Investissements financiers
    if (hasInvestissementsFinanciersBox) {
      setRoutes2((prevState) => {
        return [
          ...prevState,
          ...boxFinanciersLinks
        ]
      });
    }

    // S'il y a la BOX Cession de société
    if (hasCessionSocieteBox) {
      setRoutes2((prevState) => {
        return [
          ...prevState,
          ...boxCessionSocieteLinks
        ]
      });
    }

    // S'il y a la BOX Donations et Successions
    if (true) {
      setRoutes2((prevState) => {
        return [
          ...prevState,
          ...boxDonationsSuccessionLinks
        ]
      });
    }

    if (true) {
      setRoutes2((prevState) => {
        return [
          ...prevState,
          ...bilanPatrimonialLinks
        ]
      });
    }

    if (true) {
      setRoutes2((prevState) => {
        return [
          ...prevState,
          ...boxImmobilierLinks
        ]
      })
    }
  }, []);

  // Style du RIGHT MAIN CONTENT
  const setContentStyle = (content, state) => {
    if (content?.style) {
      content.style.cursor = state ? 'none' : 'auto'
    }
    else {
      content.style = {};
      content.style.cursor = state ? 'none' : 'auto'
    }
  }

  const setCursorContentStyle = (cursorContent, state) => {
    if (cursorContent?.style) {
      cursorContent.style = state ? {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        background: '#FDEBCE',
        height: '2rem',
        width: '2rem',
        borderRadius: '100%',
        transformOrigin: '100% 100%',
        transform: 'translate(-50%, -50%)',
        position: 'fixed',
        pointerEvents: 'none',
        zIndex: 3
      } : {}
    }
  }

  // à chaque fois que l'état du POST IT change
  useEffect(() => {
    const content = document.getElementById('right-main-content');
    const cursorContent = document.getElementById('cursor-style');

    setContentStyle(content, simulateurState?.postItData?.state || false);
    setCursorContentStyle(cursorContent, simulateurState?.postItData?.state || false)

  }, [simulateurState?.postItData?.state])

  // a little function to help us with reordering the result
  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const getItemStyle = (isDragging, draggableStyle) => {
    return {
      userSelect: "none",
      ...draggableStyle
    }
  }

  const getListStyle = isDraggingOver => ({});

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    const elements = reorder(
      items,
      result.source.index,
      result.destination.index
    );

    setItems(elements)
  }

  useEffect(() => {
    setItems([{
      id: 'item-1',
      content: <Calculator />
    }
    ])
  }, [])

  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    if (simulateurState?.showCalculatrice) {
      const calculatrice = document.querySelector('.calculator-container');
      function handleClickOutside(event) {
        if (elementDeplacableRef.current && !elementDeplacableRef.current.contains(event.target)) {
          if (calculatrice) {
            calculatrice.style.boxShadow = "none";
          }
          simulateurDispatch(enableOrDisableCalculatriceKeyBoard({
            enableCalculatriceKeyboard: false
          }))
        } else {
          if (calculatrice) {
            calculatrice.style.boxShadow = "0 0 10px";
          }
          simulateurDispatch(enableOrDisableCalculatriceKeyBoard({
            enableCalculatriceKeyboard: true
          }))
        }
      }
      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [elementDeplacableRef, simulateurState?.showCalculatrice]);


  // Déplacement de la calculatrice à travers la navigation de toutes les pages
  useEffect(() => {
    let isDragging = false;
    let offsetX, offsetY;

    if (elementDeplacableRef.current) {
      elementDeplacableRef.current.addEventListener('mousedown', (e) => {
        elementDeplacableRef.current.style.cursor = "grab";
        isDragging = true;
        offsetX = e.clientX - elementDeplacableRef.current.getBoundingClientRect().left;
        offsetY = e.clientY - Number(Math.abs(document.body.getBoundingClientRect().top) + elementDeplacableRef.current.getBoundingClientRect().top);
      });

      document.addEventListener('mousemove', (e) => {
        if (!isDragging) return;
        elementDeplacableRef.current.style.cursor = "grabbing";
        elementDeplacableRef.current.style.left = e.clientX - offsetX + 'px';
        elementDeplacableRef.current.style.top = e.clientY - offsetY + 'px';
      });

      document.addEventListener('mouseup', () => {
        if (elementDeplacableRef.current) {
          elementDeplacableRef.current.style.cursor = "grab";
        }
        offsetX = null;
        offsetY = null;
        isDragging = false;
      });
    }
  }, [])

  // Déplacement du postit à travers la navigation de toutes les pages
  useEffect(() => {
    let isDragging = false;
    let offsetX, offsetY;

    if (postitDeplacableRef.current) {
      postitDeplacableRef.current.addEventListener('mousedown', (e) => {
        postitDeplacableRef.current.style.cursor = "grab";
        isDragging = true;
        offsetX = e.clientX - postitDeplacableRef.current.getBoundingClientRect().left;
        offsetY = e.clientY - Number(Math.abs(document.body.getBoundingClientRect().top) + postitDeplacableRef.current.getBoundingClientRect().top);
      });

      document.addEventListener('mousemove', (e) => {
        if (!isDragging) return;
        postitDeplacableRef.current.style.cursor = "grabbing";
        // postitDeplacableRef.current.style.left = e.clientX - offsetX + 'px';
        // postitDeplacableRef.current.style.top = e.clientY - offsetY + 'px';

        const newLeft = e.clientX - offsetX;
        const viewportWidth = window.innerWidth;
        const elementWidth = postitDeplacableRef.current.offsetWidth;
        postitDeplacableRef.current.style.right = `${viewportWidth - newLeft - elementWidth}px`;


        const newTop = e.clientY - offsetY;
        const viewportHeight = window.innerHeight;
        const elementHeight = postitDeplacableRef.current.offsetHeight;
        postitDeplacableRef.current.style.bottom = `${viewportHeight - newTop - elementHeight}px`;
      });

      document.addEventListener('mouseup', () => {
        if (postitDeplacableRef.current) {
          postitDeplacableRef.current.style.cursor = "grab";
        }
        offsetX = null;
        offsetY = null;
        isDragging = false;
      });
    }
  }, [])

  const location = useLocation();

  useEffect(() => {
    // Detect if a route was redirected
    // Get the body element
    const bodyElement = document.body;

    if (location) {
      if (simulateurState?.showCalculatrice) {
        if (bodyElement && elementDeplacableRef.current) {
          const rect = bodyElement.getBoundingClientRect();
          const match = elementDeplacableRef.current.style.top.match(/\d+/);
          if (match) {
            const extractedNumber = match[0];
            if (Number(extractedNumber) > Number(Math.abs(rect.top) + 100) && extractedNumber > 500) {
              elementDeplacableRef.current.style.top = '100px';
            }
          }
        }
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location])

  return (
    <main style={{ height: '100%' }} id='right-main-content' onMouseMove={handleMouseMove}>
      <ExitConfirmation />

      {/** Affichage de la calculatrice */}
      {
        <div ref={elementDeplacableRef} className='element-deplacable' style={{ display: simulateurState?.showCalculatrice ? "block" : "none",  position: 'absolute', left: '417px', zIndex: 10000 }}>
          <Calculator />
        </div>
      }

      {/** Affichage du postit commentaire */}
      {/* {simulateurState?.postItData?.displayBlocPostIt && <Postit /> } */}
      {
        <div ref={postitDeplacableRef} className='element-deplacable-post-it' style={{ display: simulateurState?.postItData?.displayBlocPostIt ? "block" : "none",  position: 'absolute', right: 0, zIndex: 969 }}>
          <Postit />
        </div>
      }

      {/** Les éléments de la page */}
      <CContainer fluid>
        <Suspense fallback={loading}>
          <Switch>
            {routes2.map((route, idx) => {
              return route.component && (
                <Route
                  key={idx}
                  path={route.path}
                  exact={route.exact}
                  name={route.name}
                  render={props => (
                    <CFade>
                      <route.component {...props} />
                    </CFade>
                  )} />
              )
            })}
            <Route path='*' exact={true} component={My404} />
            {/*<Redirect from="/" to="/dashboard" />*/}
          </Switch>
        </Suspense>
      </CContainer>
    </main>
  )
}

export default React.memo(TheContent)
