const baseURL = process.env.REACT_APP_URL; // Set your API base URL

const handleResponse = async (response) => {
  if (!response.ok) {
    const error = await response.json();
    throw new Error(error.message || 'Request failed');
  }
  return response.json();
};

const PoleService = {
  /**
   * Fetch all poles for a user based on their email.
   *
   * @param {string} email - The user's email address.
   * @returns {Promise<Array>} - A promise that resolves to an array of poles.
   */
  getAllPolesByEmail: async (email) => {
    try {
      const response = await fetch(`${baseURL}/api/auth/getAllPoleUser?email=${email}`);
      return handleResponse(response);
    } catch (error) {
      console.error('Error fetching poles:', error);
      throw error;
    }
  },

  /**
   * Fetch a pole by its ID.
   *
   * @param {string} poleId - The ID of the pole.
   * @returns {Promise<Object>} - A promise that resolves to the pole data.
   */
  getPoleById: async (poleId) => {
    try {
      const response = await fetch(`${baseURL}/api/auth/getPoleById?id=${poleId}`);
      return handleResponse(response);
    } catch (error) {
      console.error('Error fetching pole by ID:', error);
      throw error;
    }
  },

  /**
   * Create a new pole.
   *
   * @param {Object} poleData - The data for the new pole.
   * @returns {Promise<Object>} - A promise that resolves to the created pole.
   */
  createPole: async (poleData) => {
    try {
      const response = await fetch(`${baseURL}/api/auth/createPole`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(poleData),
      });
      return handleResponse(response);
    } catch (error) {
      console.error('Error creating pole:', error);
      throw error;
    }
  },

  /**
   * Update a pole by its ID.
   *
   * @param {string} poleId - The ID of the pole.
   * @param {Object} updatedData - The updated data for the pole.
   * @returns {Promise<Object>} - A promise that resolves to the updated pole.
   */
  updatePole: async (updatedData) => {
    try {
      const response = await fetch(`${baseURL}/api/auth/changePole`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(updatedData),
      });
      return handleResponse(response);
    } catch (error) {
      console.error('Error updating pole:', error);
      throw error;
    }
  },

  /**
   * Delete a pole by its ID.
   *
   * @param {string} poleId - The ID of the pole.
   * @returns {Promise<void>} - A promise that resolves when the pole is deleted.
   */
  deletePole: async (poleId) => {
    try {
      const response = await fetch(`${baseURL}/api/auth/deletePole/${poleId}`, {
        method: 'DELETE',
      });
      return handleResponse(response);
    } catch (error) {
      console.error('Error deleting pole:', error);
      throw error;
    }
  },
};

export default PoleService;
